import React from "react";
import classNames from "classnames";
import classes from "./Carousel.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import { useTranslation } from "next-i18next";
import { settings } from "./Carousel.settings";
import { ReactBootstrapHTMLContainerProps } from "../../../types/htmlElementTypes";

interface Props extends ReactBootstrapHTMLContainerProps { }

const Carousel = ({ className, ...htmlProps }: Props) => {
  const { t } = useTranslation("homepage");

  const carouselValues = [
    { value: '25+', label: t("carousel.first_item") },
    { value: '80+', label: t("carousel.second_item") },
    { value: '10+', label: t("carousel.third_item") },
    { value: '40+', label: t("carousel.fourth_item") },
    { value: '7+', label: t("carousel.fifth_item") },
    { value: '3+', label: t("carousel.sixth_item") },
    { value: '35+', label: t("carousel.seventh_item") },
  ];

  return (
    <div {...htmlProps} className={classNames(classes.carousel, className)}>
      <div className={classes.carousel__container}>
        <Slider {...settings} >
          {carouselValues.map((value, index) => (
            <div
              key={`carousel-item-${index}`}
              className={classNames(classes.carousel__slide)}
            >
              <div className={classes.carousel__line}></div>
              <div
                id={"carousel-value-" + index}
                className={classes.carousel__item}
              >
                <div>
                  <p className={classes.carousel__number}>{value.value}</p>
                  <p className={classes.carousel__text}>{value.label}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
