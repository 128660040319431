export const settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
  centerMode: false,
  draggable: false,
  variableWidth: false,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        centerMode: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        centerMode: true,
      },
    },
    {
      breakpoint: 868,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        centerMode: true,
      },
    },
  ],
};
